<template>
  <div>
    <slot />
  </div>
</template>

<script setup lang="ts">
const language = useCurrentLanguage()
const styles = await useCurrentStyles()

useHead({
  htmlAttrs: {
    lang: language.value,
    style: styles,
    class: 'bg-header-footer-background',
  },
})
</script>
