export type ChartColorPalette = 'sand' | 'violett'

export type ChartBarDistribution = 'percentage' | 'largest'

const SAND = ['#9C785A']
const SANDDUO = ['#9C785A', '#7F8385']
const SANDSPECTRUM = [
  '#CDBEA4',
  '#C0AA8D',
  '#AA8965',
  '#9C785A',
  '#82624C',
  '#6A5142',
  '#574337',
  '#2E221C',
]

const VIOLETT = ['#69236b']
const VIOLETTDUO = ['#69236b', '#7F8385']
const VIOLETTSPECTRUM = [
  '#EFD6F8',
  '#E6B5F2',
  '#D78AE8',
  '#C35CD9',
  '#A83CBD',
  '#8C2F9C',
  '#752880',
  '#3E0E44',
]



export const SANDREVERSED = SANDSPECTRUM.reverse()
export const VIOLETTREVERSED = VIOLETTSPECTRUM.reverse()

export function getColorPalette(
  color: ChartColorPalette,
  isReversed: boolean,
  items: number,
): string[] {
  if (color === 'sand') {
    if (items == 1) {
      return SAND
    }
    if (items == 2) {
      return SANDDUO
    }
    if (isReversed) {
      return SANDREVERSED
    } else {
      return SANDSPECTRUM
    }
  } else if (color === 'violett') {
    if (items == 1) {
      return VIOLETT
    }
    if (items == 2) {
      return VIOLETTDUO
    }
    if (isReversed) {
      return VIOLETTREVERSED
    } else {
      return VIOLETTSPECTRUM
    }
  }
  return SANDSPECTRUM
}

export function getSymbols(value: string): number[] {
  const match = /{([^}]*)}/g.exec(value)
  return (
    match?.[1]
      .split(',')
      .map((v) => {
        return parseInt(v.trim())
      })
      .filter((v) => !isNaN(v)) || []
  )
}

export function getNiceFraction(fraction: number, round: boolean) {
  if (round) {
    if (fraction < 1.5) {
      return 1
    } else if (fraction < 3) {
      return 2
    } else if (fraction < 7) {
      return 5
    }
  } else if (fraction <= 1) {
    return 1
  } else if (fraction <= 2) {
    return 2
  } else if (fraction <= 5) {
    return 5
  }

  return 10
}

export function getSaneNumber(range: number, round: boolean) {
  const exponent = Math.floor(Math.log10(range))
  const fraction = range / Math.pow(10, exponent)
  const niceFraction = getNiceFraction(fraction, round)
  return niceFraction * Math.pow(10, exponent)
}

export function convertFootnotes(v: string): string {
  return v.replace(/\{(\d+(?:,\s*\d+)*)\}/, function (_v, match: string) {
    return match
      .split(',')
      .map((v) => v.trim())
      .map((v) => {
        return `<sup>${v}</sup>`
      })
      .join('')
  })
}
