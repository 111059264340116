<template>
  <div
    class="max-h-[calc(100vh-40px)] overflow-hidden relative mb-10"
    :class="[
      isMarketingHero ? 'marketing-gradient-overlay' : 'gradient-overlay',
    ]"
  >
    <MediaImage
      v-if="hero"
      v-blokkli-droppable:field_hero_image="{
        entityType: 'media',
        entityBundles: ['image'],
        cardinality: 1,
      }"
      hide-caption
      v-bind="entity?.hero || hero"
      preload
      loading="eager"
      :image-style="imageStyle"
      class=""
      :class="!isMarketingHero && !isLanding ? 'hero-image' : 'w-full'"
    />
    <Container>
      <div class="grid-container absolute bottom-6 md:bottom-12">
        <h1
          v-if="isLanding || isMarketingHero"
          v-blokkli-editable:title
          class="text-white col-span-4 sm:col-span-6 md:col-span-6 lg:col-span-8 hyphens-none"
          :class="{
            'text-4xl md:text-5xl lg:text-6xl xl:text-7xl': isMarketingHero,
            'text-4xl md:text-5xl lg:text-7xl xl:text-8xl': !isMarketingHero,
          }"
        >
          {{ entity?.title || title }}
        </h1>
        <div
          v-if="lead && isMarketingHero"
          class="col-span-4 sm:col-span-6 md:col-span-6 lg:col-span-8 hyphens-none text-white"
        >
          <div
            v-blokkli-editable:field_lead="{ type: 'frame' }"
            class="text-lg lg:text-xl"
            v-html="lead"
          />
        </div>
        <div
          v-if="heroLink && isMarketingHero"
          class="col-span-4 sm:col-span-6 md:col-span-6 lg:col-span-8 hyphens-none pb-10 text-white"
        >
          <VuepalLink :to="heroLink?.uri?.path" class="button is-filled">
            {{ heroLink.title }}

            <SpriteSymbol name="arrow-right" class="size-6" />
          </VuepalLink>
        </div>
      </div>
    </Container>
  </div>
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

const props = defineProps<{
  hero?: NodePageFragment['hero']
  isMarketingHero?: boolean
  heroLink?: NodePageFragment['heroLink']
  title?: string
  lead?: NodePageFragment['lead']
  entity?: any
  isLanding: boolean
}>()

const bigGrid = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 770,
      aspectRatio: 1,
    },
    sm: {
      width: 985,
      aspectRatio: 16 / 9,
    },
    md: {
      width: 1381,
      aspectRatio: 16 / 9,
    },
    lg: {
      width: 2561,
      aspectRatio: 16 / 9,
    },
  },
})

const marketingGrid = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 770,
      aspectRatio: 9 / 16,
    },
    sm: {
      width: 985,
      aspectRatio: 16 / 9,
    },
    md: {
      width: 1381,
      aspectRatio: 16 / 9,
    },
    lg: {
      width: 2561,
      aspectRatio: 16 / 9,
    },
  },
})

const smallGrid = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 728,
      aspectRatio: 1,
    },
    sm: {
      width: 1024,
      aspectRatio: 16 / 6,
    },
    md: {
      width: 1400,
      aspectRatio: 16 / 6,
    },
    lg: {
      width: 2600,
      aspectRatio: 16 / 6,
    },
  },
})

const imageStyle = computed(() => {
  if (props.isMarketingHero) {
    return marketingGrid
  }
  return props.isLanding ? bigGrid : smallGrid
})
</script>

<style lang="postcss">
.hero-image img {
  @apply max-h-[500px] object-cover;
}
</style>
